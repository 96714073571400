const warehouseSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'warehouse.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Sales Order',
    is_heading: false,
    is_active: false,
    link: {
      name: 'warehouse.sales-order'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-shopping-cart-fill'
  },
  {
    title: 'Sales Order Tracker',
    is_heading: false,
    is_active: false,
    link: {
      name: 'warehouse.sales-order-tracker'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-map-pin-range-fill'
  }
]

export default warehouseSideMenu
