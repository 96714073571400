const AdminSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Maintenance',
    name: 'sidebar.admin.maintenance',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Users',
        is_active: false,
        link: {
          name: 'admin.maintenance.users'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Distributions',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.maintenance.distributions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-building-line'
      },
      {
        title: 'Warehouses',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.maintenance.warehouses'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-5-line'
      },
      {
        title: 'Sale People',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.maintenance.sale-people'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-user-line'
      },
      {
        title: 'Customers',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.maintenance.customers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-user-line'
      },
      {
        title: 'Product Categories',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.maintenance.product-categories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-order-play-line'
      },
      {
        title: 'Products',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.maintenance.products'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-store-2-line'
      },
      {
        title: 'Discounts',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.maintenance.discounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-percent-line'
      }
    ]
  }
]

export default AdminSideMenu
